import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "TransUnion is Looking for Talent by MOS/AOC Code",
  "author": "Anthony",
  "date": "2018-11-01T17:24:26.000Z",
  "categories": ["Veteran Hiring"],
  "slug": "transunion-knows-what-they-want-when-it-comes-to-veteran-talent",
  "draft": false,
  "meta_title": "TransUnion is Looking for Talent by MOS/AOC Code"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`When we first sat down with TransUnion, they explained three things upfront:`}</p>
    <ol>
      <li parentName="ol">{`They want to hire Veterans because they contribute to the bottom line; In other words, they value our talent`}</li>
      <li parentName="ol">{`They require experienced talent; Something we all have - Officers, NCOs, Junior Officers, and Junior Enlisted`}</li>
      <li parentName="ol">{`They also know what they're looking for`}</li>
    </ol>
    <p>{`Let's look at point 3 - Here is what TransUnion is currently searching for in MOS/AOC speak:`}</p>
    <p><strong parentName="p">{`Army`}</strong></p>
    <ul>
      <li parentName="ul">{`Army Cyber Operations - Officer (AOC 17A)`}</li>
      <li parentName="ul">{`Army Cyber Operations Specialist - Enlisted (MOS 17C)`}</li>
      <li parentName="ul">{`Army Cyber Network Defender - Enlisted (MOS 25D)`}</li>
      <li parentName="ul">{`Army Cryptologic Cyberspace Intelligence Collector/Analyst - Warrant Officer (MOS 35Q)`}</li>
      <li parentName="ul">{`Information Protection Technician - Warrant Officer (MOS 255S)`}</li>
      <li parentName="ul">{`Army Information Services Technician - Warrant Officer (MOS 255A)`}</li>
      <li parentName="ul">{`Army Network Management Technician - Warrant Officer (MOS 255N)`}</li>
      <li parentName="ul">{`Army Information Systems Operator/Analyst - Enlisted (MOS 29)`}</li>
      <li parentName="ul">{`Army Operations Research and Systems Analysis (ORSA) - Officer (FA 49)`}</li>
      <li parentName="ul">{`Army Intelligence - Officer AOC (35)`}</li>
      <li parentName="ul">{`Army Finance - Officer (AOC 44)`}</li>
      <li parentName="ul">{`Army Engineer - Officer (AOC 12A)`}</li>
      <li parentName="ul">{`Army Signal - Officer(AOC 25A)`}</li>
      <li parentName="ul">{`Army Acquisition Officer (FA 51)`}</li>
      <li parentName="ul">{`Army Intelligence Analyst - Enlisted (MOS 35F)`}</li>
      <li parentName="ul">{`Army Operations Specialist - Enlisted (All Combat Arms MOS)`}</li>
    </ul>
    <p><strong parentName="p">{`Air Force`}</strong></p>
    <ul>
      <li parentName="ul">{`Air Force Cyber Operations - Officer (AFSC 17SX)`}</li>
      <li parentName="ul">{`Air Force Operations Research Analyst - Officer (AFSC 61AX)`}</li>
      <li parentName="ul">{`Air Force Intelligence - Officer (AFSC 3 Or 4)`}</li>
      <li parentName="ul">{`Air Force Network Operations - Officer (AFSC 17DX)`}</li>
      <li parentName="ul">{`Air Force Acquisition Manager - Officer (63AX)`}</li>
      <li parentName="ul">{`Air Force Intelligence - Officer (AFSC 3 Or 4)`}</li>
      <li parentName="ul">{`Air Force Operations Intelligence - Enlisted (AFSC 1N0X1)`}</li>
      <li parentName="ul">{`Air Force Knowledge Operation Management - Enlisted (AFSC 3D0X1)`}</li>
      <li parentName="ul">{`Air Force Computer Systems Programming - Enlisted (AFSC 3D0X4)`}</li>
    </ul>
    <p><strong parentName="p">{`Navy`}</strong></p>
    <ul>
      <li parentName="ul">{`Navy Cyber Warfare Engineer Officer (NEC 184X)`}</li>
      <li parentName="ul">{`Navy Cyber - Warrant Officer (784X)`}</li>
      <li parentName="ul">{`Navy Network Security Vulnerability Technician - Enlisted (NEC 2780)`}</li>
      <li parentName="ul">{`Navy Operation Research - Officer (NEC 3211E)`}</li>
      <li parentName="ul">{`Navy Intelligence - Officer (NEC 183X)`}</li>
      <li parentName="ul">{`Navy Information Systems Technician - Warrant Officer (NEC 782X)`}</li>
    </ul>
    <p><strong parentName="p">{`Marines`}</strong></p>
    <ul>
      <li parentName="ul">{`Marine Corps Cyberspace Officer (MOS 17XX)`}</li>
      <li parentName="ul">{`Marine Corps Cyber - Warrant Officer (MOS 170A)`}</li>
      <li parentName="ul">{`Marine Corps Cyber Technicians - Enlisted (MOS 0689)`}</li>
      <li parentName="ul">{`Marine Corps Operations Research Specialist (MOS 8051)`}</li>
      <li parentName="ul">{`Marine Corps - Officer (MOS 0203)`}</li>
      <li parentName="ul">{`Marine Corps Network Operations and Systems Officer - Officer (MOS 0605)`}</li>
      <li parentName="ul">{`Marine Intelligence Specialist - Enlisted (MOS 0231)`}</li>
      <li parentName="ul">{`Navy Operation Research - Officer (NEC 3211E)`}</li>
      <li parentName="ul">{`Marine Corps Operations Research Specialist (MOS 8051)`}</li>
      <li parentName="ul">{`Marine Corps Tactical Communications Planning and Engineer Officer - Warrant Officer (MOS 0620)`}</li>
    </ul>
    <p>{`If you fall in one of these MOS or AOC categories and are interested in a potential career with TransUnion, click the button below to get started. If you don't, I recommend registering anyways! Purepost has partnered with TransUnion to hire Veterans and it just takes 10 mins to create a profile that TransUnion will see immediately.`}</p>
    <p>{`Thanks for your time!`}</p>
    <p>{`Anthony Garcia, CEO`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      